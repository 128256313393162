import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'

class GoogleReviews extends React.Component {
  render() {
    const { data } = this.props
    const { edges: reviews } = data.allGooglePlacesReview

    // const star = () => {
    //   return(<p text=" :star: "/>)
    // }
    // const stars 
    // var renderStars = function(rating){
    //   var stars = "<div class='review-stars'><ul>";
                        
    //   // fill in gold stars
    //   for (var i = 0; i < rating; i++) {
    //     stars = stars+"<li><i class='star'></i></li>";
    //   };

    //   // fill in empty stars
    //   if(rating < 5){
    //     for (var i = 0; i < (5 - rating); i++) {
    //       stars = stars+"<li><i class='star inactive'></i></li>";
    //     };
    //   }
    //   stars = stars+"</ul></div>";
    //   return stars;
    // }

    return (
      <div style={{padding: '2em'}}>
        <div className="columns is-multiline" >

        {reviews &&
          reviews.map(({ node: review }) => (
            <div key={review.text} className="column" style={{padding: '2em'}}>
              <section style={{padding: '2em', width:'350px' }}>
                <span>
                  <img style={{width: '50px'}} src={review.profile_photo_url} alt="Reviewer Google profile" title={review.author_name + "'s google profile photo"}  />
                </span>

                <span>{review.author_name}</span>
                <div>
                  <div>
                    <ul style={{ display: 'flex', width: '100%', listStyle: 'none', alignItems: 'center'}}>
                      <li style={{ listStyle: 'none', lineHeight: '1em'}}>
                        <a href={review.author_url} rel="noreferrer noopener" target="_blank" >
                          {review.rating}.0
                        </a>
                      </li>

                      <li style={{ listStyle: 'none', lineHeight: '1em'}}><i style={{color: '#E4B248', fontSize: '1.4em', fontStyle: 'normal'}}>&#9733;</i></li>
                      <li style={{ listStyle: 'none', lineHeight: '1em'}}><i style={{color: '#E4B248', fontSize: '1.4em', fontStyle: 'normal'}}>&#9733;</i></li>
                      <li style={{ listStyle: 'none', lineHeight: '1em'}}><i style={{color: '#E4B248', fontSize: '1.4em', fontStyle: 'normal'}}>&#9733;</i></li>
                      <li style={{ listStyle: 'none', lineHeight: '1em'}}><i style={{color: '#E4B248', fontSize: '1.4em', fontStyle: 'normal'}}>&#9733;</i></li>
                      <li style={{ listStyle: 'none', lineHeight: '1em'}}><i style={{color: '#E4B248', fontSize: '1.4em', fontStyle: 'normal'}}>&#9733;</i></li>
                    </ul>
                  </div>
                </div>

                {/* <a
                  href={review.author_url}
                >
                  See this review on Google
                </a> */}
                <p>{review.text}</p>
                  
              </section>
            </div>
          ))}
          
        </div>
        <div>
            <ul style={{ display: 'flex', width: '100%', listStyle: 'none', alignItems: 'center', justifyContent: 'center'}}>
              <li style={{ listStyle: 'none', lineHeight: '1em', margin: '1em'}}>
                <a 
                  className="btn"
                  href="https://g.page/advanceautomovers/review?gm"
                  rel="noreferrer noopener"
                  target="_blank"
                  style={{background: 'rgba(220,220,220,0.7)'}}
                >
                  Leave a Review
                </a>
              </li>
              <li style={{ listStyle: 'none', lineHeight: '1em'}}>
                <a 
                  className="btn"
                  href="https://g.page/advanceautomovers/review?gm"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Our Google Profile
                </a>
              </li>
            </ul>
          </div>
      </div>
    )
  }
}

GoogleReviews.propTypes = {
  data: PropTypes.shape({
    allGooglePlacesReview: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allGooglePlacesReview {
          edges {
            node {
              author_name
              author_url
              text
              rating
              profile_photo_url    
            }
          }
        }
      }
    `}
    render={(data, count) => <GoogleReviews data={data} count={count} />}
  />
)
