import React from 'react'
import PropTypes from 'prop-types'

const Advantages = ({ data }) => (
  <div className="columns is-multiline" >
    {data.map(advantage => (
      <div key={advantage.point} className="column is-6">
      <section className="section">
        <div className="has-text-centered">
          <div
            style={{
              width: '240px',
              display: 'inline-block',
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 24 24"><path d={advantage.path}/></svg>
            <div
              className="point-content"
              dangerouslySetInnerHTML={{ __html: advantage.html }}
            />
          </div>
        </div>
        <h4>{advantage.point}</h4>
        <p>{advantage.description}</p>
      </section>
    </div>
    ))}
  </div>
)

Advantages.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      point: PropTypes.string,
      description: PropTypes.string,
      path: PropTypes.string,
      html: PropTypes.string,
    })
  ),
}

export default Advantages
