import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const CredentialGrid = ({ gridItems }) => (
  <div className="columns is-multiline" style={{background: 'rgba(225, 225, 225, 0.6)', boxShadow: '20px 20px 50px grey'}}>
    {gridItems.map(item => (
      <div key={item.alt} className="column">
          <div className="has-text-centered">
            <div
              style={{
                width: '200px',
                display: 'inline-block',
              }}
            >
              <a
                href={item.link}
                rel="noreferrer noopener"
                target="_blank"
              >
                <PreviewCompatibleImage 
                  imageInfo={{
                    image: item.image,
                    alt: `${item.alt}`,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{ __html: item.html }}
                />
              </a>
            </div>
          </div>
      </div>
    ))}
  </div>
)

CredentialGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      alt: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
      html: PropTypes.string,
    })
  ),
}

export default CredentialGrid
