import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import Highlights from '../components/Highlights'
import Services from '../components/Services'
import Advantages from '../components/Advantages'
import Credentials from '../components/Credentials'
import GetStarted from '../components/GetStarted'
import BlogRoll from '../components/BlogRoll'
import GoogleReviews from '../components/GoogleReviews'

// ADDED HELMET W 'RELIABLE AUTO TRANSPORT' IN TITLE
// REMOVED HELMET DUE TO SEOBILITY RANKING DATA

export const IndexPageTemplate = ({
  helmet,
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  advantages,
  credentials,
  getStarted,
}) => (
  <div>
    {helmet || ''}

    <div
      className="full-width-image"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        height: '90vh',

      }}
    >
      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
            boxShadow:
              'rgba(0, 100, 150, 0.9) 0.5rem 0px 0px, rgba(0, 100, 150, 0.9) -0.5rem 0px 0px',
            backgroundColor: 'rgb(0, 100, 150)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {title}
        </h1>
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            boxShadow:
              'rgba(0, 100, 150, 0.9) 0.5rem 0px 0px, rgba(0, 100, 150, 0.9) -0.5rem 0px 0px',
            backgroundColor: 'rgba(0, 100, 150, 0.9)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {subheading}
        </h3>
      </div>
    </div>

    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">

                <div className="content">
                  
                  <div className="column is-10 is-offset-1">
                    <Highlights gridItems={mainpitch.highlights} />
                  </div>
                  <hr/>

                  <div className="tile">
                    <h2 className="title">{mainpitch.title}</h2>
                  </div>
                  <div>
                    <p className="subtitle">{mainpitch.description}</p>
                  </div>

                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/about">
                      About Us
                    </Link>
                  </div>
                </div>

                <hr/>

                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      {heading}
                    </h3>
                    <p>{description}</p>
                  </div>
                </div>                
                <Services gridItems={intro.services} />
                <div className="columns">
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/services">
                      View Services
                    </Link>
                  </div>   
                </div>

                <hr/>

                <div>
                  <h2 className="has-text-weight-semibold is-size-2">{advantages.heading}</h2>
                  <p className="subtitle has-text-weight-semibold">{advantages.description}</p>
                  <Advantages data={advantages.points} />
                  <br/>
                </div>

                <hr/>
                <span>
                  <Credentials gridItems={credentials.links} />
                </span>
                
                <hr/>

                <div>
                  <h2 className="has-text-weight-semibold is-size-2">
                    {getStarted.heading}
                  </h2>
                  <p className="is-size-5">{getStarted.description}</p>
                  <GetStarted data={getStarted.steps} />
                </div>

                <hr/>

                <div>
                  <div className="cta-lower">
                    <h3 className="cta-heading" style={{color:'white', textTransform:'uppercase', fontWeight:'bold'}}>Start Your Reliable Car Shipping Service Today</h3>
                      <a className="cta-btn" href="https://www.bbb.org/atlanta/pages/connect/form-direct.html/28119305/" rel="noreferrer noopener" target="_blank" >Request a Quote</a>
                    
                    <p>or Call Now to Speak with an Expert Vehicle Shipping Agent</p>
                    <nobr>
                      <a href="tel:6784359348" className="cta-btn">Call Now</a>
                    </nobr>
                  </div>
                </div>
                
                <GoogleReviews />

                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    Latest stories
                  </h3>
                  <BlogRoll />
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                      View All Stories
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  helmet: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  mainpitch: PropTypes.shape({
    highlights: PropTypes.array,
  }),
  intro: PropTypes.shape({
    services: PropTypes.array,
  }),
  advantages: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    points: PropTypes.array,
  }),
  credentials: PropTypes.shape({
    links: PropTypes.array,
  }),
  getStarted: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    steps: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        helmet={
          <Helmet titleTemplate="%s | Advance Auto Movers">
            <title>{`${frontmatter.title}`}</title>
            {/* <meta
              name="description"
              content={`${frontmatter.description}`}
            /> */}
          </Helmet>
        }
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        advantages={frontmatter.advantages}
        credentials={frontmatter.credentials}
        getStarted={frontmatter.get_started}
      />

      {/* <div 
        id="pm_embed" 
        align="center" 
        style={{
          maxWidth: '400px',
        }}
      >
        <iframe src='//embed.vymaps.com/?embed_url=https://vymaps.com/US/Advance-Auto-Movers-691443031281739/' width='100%' height='500px' border='solid 1px'></iframe>
      </div> */}
      
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        
        mainpitch {
          highlights {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            text
          }
          title
          description
        }
        description
        intro {
          services {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            text
          }
          heading
          description
        }
        advantages {
          heading
          description
          points {
            point
            description 
            path
            html
          }
        }
        credentials {
          links {
            html
            alt
            link
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        get_started {
          heading
          description
          steps {
            step
            title
            description
          }
        }
      }
    }
  }
`
